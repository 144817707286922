import React from 'react';
import cn from 'classnames';
import SEO from '@components/seo';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '@utility/Link';
import ColumnLayout from '@components/ColumnLayout';

const NotFound = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityNotFound {
          edges {
            node {
              heading
              links {
                ... on SanityExternalLink {
                  _key
                  _type
                  url
                  label
                }
                ... on SanityRelativeLink {
                  _key
                  _type
                  label
                  url
                }
                ... on SanityInternalLink {
                  _key
                  _type
                  page {
                    ... on SanityBlogCategory {
                      id
                      slug {
                        current
                      }
                    }
                    ... on SanityBlogPost {
                      id
                      slug {
                        current
                      }
                    }
                    ... on SanityCollection {
                      id
                      slug {
                        current
                      }
                    }
                    ... on SanityFinishCategory {
                      id
                      slug {
                        current
                      }
                    }
                    ... on SanityPage {
                      id
                      slug {
                        current
                      }
                    }
                    ... on SanityProduct {
                      id
                      slug {
                        current
                      }
                    }
                  }
                  label
                }
              }
              subheading
              image {
                asset {
                  _id
                }
              }
            }
          }
        }
      }
    `,
  );
  const {
    heading,
    subheading,
    image,
    links,
  } = data.allSanityNotFound.edges[0].node;

  return (
    <div className="wrapper">
      <div className="lg:mt-12 mb-32" style={{ minHeight: '600px' }}>
        <ColumnLayout heading={heading} subheading={subheading} image={image}>
          <SEO title="Page Not Found">
            <meta name="robots" content="noindex" />
          </SEO>

          {links && (
            <div className="flex">
              {links.map((link, i) => (
                <Link
                  key={link._key}
                  to={link}
                  internalExternal
                  styled={false}
                  className={cn(
                    'btn-default type-upper-140 text-center',
                    {
                      'btn-secondary mr-8': i === 0,
                    },
                    {
                      'btn-black': i === 1,
                    },
                  )}
                  aria-label={link.label}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          )}
        </ColumnLayout>
      </div>
    </div>
  );
};

export default NotFound;
